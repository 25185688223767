import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Layout, renderModules } from '../../components';
import { HeroBackgroundImage } from '../../components/images';
import RichText from '../../components/rich-text';
import './index.scss';

export default function SolutionCategory ({ path, data }) {
  const {
    title, heroHeading, heroText, modules, metaDescription, heroBackgroundImage, ogImage
  } = data.contentfulSolutionCategory;

  return (
    <Layout currentPath={path}>
      <Helmet>
        <title>{title?.title}</title>
        <meta name="description" content={metaDescription?.metaDescription} />
        <meta name="og:title" content={title?.title} />
        <meta name="og:description" content={metaDescription?.metaDescription} />
        <meta name="og:image" content={ogImage?.fixed?.src} />
      </Helmet>

      <section className="hero">
        <HeroBackgroundImage heroBackgroundImage={heroBackgroundImage} />
        <div className="container hero__inner">
          <h3>{heroHeading?.heroHeading}</h3>
          <div className="hero-text"><RichText node={heroText} /></div>
        </div>
      </section>

      {renderModules(modules)}
    </Layout>
  );
}

export const pageQuery = graphql`
query SolutionCategoryById($id: String!) {
  contentfulSolutionCategory(id: {eq: $id}) {
    id
    title { title }
    metaDescription { metaDescription }
    heroHeading { heroHeading }
    heroText { raw }

    ogImage: heroBackgroundImage {
      ...pageOgImageFields
    }

    heroBackgroundImage {
      ...heroBackgroundImageFields
    }

    modules {
      __typename
      ... on ContentfulArticleContentModule {
        ...articleContentModuleFields
      }
      ... on ContentfulBeyondZeroHarmModule {
        ...beyondZeroHarmModuleFields
      }
      ... on ContentfulContactModule {
        ...contactModuleFields
      }
      ... on ContentfulCtaArrowsModule {
        ...ctaArrowsModuleFields
      }
      ... on ContentfulFeatureModule {
        ...featureModuleFields
      }
      ... on ContentfulFeaturedResourcesModule {
        ...featuredResourcesModuleFields
      }
      ... on ContentfulFeaturedSolutionsModule {
        ...featuredSolutionsModuleFields
      }
      ... on ContentfulImageVideoModule {
        ...imageVideoModuleFields
      }
      ... on ContentfulImagesWithArrowModule {
        ...imagesWithArrowModuleFields
      }
      ... on ContentfulLeadershipModule {
        ...leadershipModuleFields
      }
      ... on ContentfulPrimaryContentModule {
        ...primaryContentModuleFields
      }
      ... on ContentfulQuoteModule {
        ...quoteModuleFields
      }
      ... on ContentfulSideBlockModule {
        ...sideBlockModuleFields
      }
      ... on ContentfulTwoColumnModule {
        ...twoColumnModuleFields
      }
    }
  }
}
`;
